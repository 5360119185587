<template>
  <div>
    <v-dialog v-model="state.dialog" :persistent="true" width="488">
      <v-card class="modal">
        <v-card-title>
          <div class="mx-3">
            <v-icon color="success" size="36">mdi-check-circle-outline</v-icon>
          </div>
          Item adicionado com sucesso!
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="mt-4">
          <h3>Deseja cadastrar novo resíduo ou equipamento?</h3>

          <p class="mt-3">
            Caso clique em “sim” o cadastro se repetirá para que um novo
            equipamento ou resíduo seja adicionado
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="d-flex flex-wrap justify-space-between">
          <v-btn
            class="mt-1 cancel-button black-3--text"
            color="#ffffff"
            :block="$isMobile"
            @click="refuseFn"
            >Não</v-btn
          >
          <v-btn
            type="button"
            color="secondary-color-1"
            :block="$isMobile"
            class="ma-0 mt-1 next-step-button white--text"
            @click="acceptFn"
            >Sim</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: "ContinueRegisterDialog",

    props: {
      state: {
        type: Object,
        required: true
      },
      acceptFn: {
        type: Function,
        required: true
      },
      refuseFn: {
        type: Function,
        required: true
      }
    }
  };
</script>

<style></style>
