<template>
  <div>
    <div v-if="mode.showFilterInput" class="px-5">
      <v-text-field
        solo
        flat
        full-width
        background-color="white-1"
        color="secondary-color-1"
        placeholder="Buscar (Ex: Marca, modelo)"
        prepend-inner-icon="mdi-magnify"
        @input="debounceInput"
      ></v-text-field>
    </div>

    <div v-if="mode.showList && equipments.length">
      <div class="mx-10 d-flex">
        <h3
          class="ml-5 black-3--text"
          :class="{
            'first-title-desktop': !$isMobile,
            'first-title-mobile': $isMobile
          }"
        >
          Marca
        </h3>
        <h3 class="black-3--text">Modelo</h3>
      </div>
    </div>

    <v-divider></v-divider>

    <div v-if="mode.showList && equipments.length" class="data-container mx-7">
      <div v-if="hasSelectedEquipment && !selectedEquipmentIsInList">
        <select-equipment-checkbox
          :equipment="selectedEquipment"
          v-model="selectedEquipment"
        />
      </div>
      <div v-for="(equipment, index) in equipments" :key="`equipment-${index}`">
        <select-equipment-checkbox
          :equipment="equipment"
          v-model="selectedEquipment"
        />
      </div>

      <div>
        <infinite-loading spinner="waveDots" @infinite="loadMore">
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>
      </div>
    </div>

    <div
      class="mt-5"
      v-if="mode.showNotFound && !equipments.length && !isLoading"
    >
      <equipment-not-found :title="equipmentNotFound" />
    </div>

    <div v-if="mode.showCreateForm" class="mx-10 mt-5">
      <v-row>
        <v-row dense>
          <v-col cols="12" md="6">
            <brand-field name="brand" :isRequired="true" v-model="form.brand" />
          </v-col>
          <v-col cols="12" md="6">
            <field
              v-model="form.model"
              type="genericText"
              label="Modelo"
              name="model"
              :isRequired="true"
            />
          </v-col>
        </v-row>
      </v-row>
    </div>

    <div v-if="mode.showBtnCreate" class="mx-5">
      <btn-dashed
        title="Cadastrar Equipamento"
        :btnAction="prepareCreateEquipment"
        block
      />
    </div>
  </div>
</template>

<script>
  import SelectEquipmentCheckbox from "@/modules/stock/batch/views/components/equipment-picker/SelectEquipmentCheckbox";
  import BtnDashed from "@/modules/core/views/components/BtnDashed";
  import EquipmentNotFound from "@/modules/stock/batch/views/components/EquipmentNotFound";
  import Field from "@/modules/core/views/components/Field";
  import BrandField from "@/modules/stock/batch/views/components/BrandField";
  import InfiniteLoading from "vue-infinite-loading";

  import { debounce, pickBy, identity } from "lodash";

  import { mapActions, mapGetters } from "vuex";

  export default {
    name: "SelectOrCreateEquipment",

    props: {
      form: {
        type: Object,
        required: true
      },
      fnPatchForm: {
        type: Function,
        required: true
      }
    },

    components: {
      EquipmentNotFound,
      BtnDashed,
      Field,
      BrandField,
      SelectEquipmentCheckbox,
      InfiniteLoading
    },

    data: () => ({
      equipments: [],

      selectedEquipment: undefined,

      filterPristine: true,
      searchInput: "",
      page: 1,
      limit: 15,

      mode: undefined,
      modes: {
        SELECT: {
          showFilterInput: true,
          showNotFound: true,
          showCreateForm: false,
          showBtnCreate: true,
          showList: true
        },
        CREATE: {
          showFilterInput: false,
          showNotFound: false,
          showCreateForm: true,
          showBtnCreate: false,
          showList: false
        }
      }
    }),

    created() {
      this.setSelectMode();
    },

    async mounted() {
      this.equipments = await this.searchEquipments();
    },

    watch: {
      selectedEquipment: {
        deep: true,
        handler: function(newVal) {
          if (newVal) {
            return this.fnPatchForm(newVal);
          }
          return this.fnPatchForm(null);
        }
      }
    },

    computed: {
      ...mapGetters(["isLoading"]),

      equipmentNotFound() {
        if (this.filterPristine) {
          const equipment =
            this.form?.equipmentType?.description || "equipamento";
          return [
            `Nenhum(a) ${equipment} cadastrado`,
            `Cadastre um(a) ${equipment}`
          ];
        }
        return ["Equipamento não encontrado", "Cadastre um equipamento"];
      },

      hasSelectedEquipment() {
        return !!this.selectedEquipment;
      },

      selectedEquipmentIsInList() {
        return this.equipments.some(
          equipment => equipment.id === this.selectedEquipment?.id
        );
      }
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("stock/batch", ["getEquipments"]),

      clearSearch() {
        this.page = 1;
        this.equipments = [];
      },

      clearSelectedEquipment() {
        this.selectedEquipment = undefined;
      },

      debounceInput: debounce(async function(value) {
        this.clearSearch();

        this.filterPristine = false;
        this.searchInput = value;

        this.equipments = await this.searchEquipments();
      }, 800),

      async searchEquipments() {
        try {
          const payload = this.mountPayload();
          const { data } = await this.getEquipments(payload);

          return data;
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro, tente novamente",
            type: "error"
          });
        }
      },

      mountPayload() {
        const payload = {
          search: this.searchInput,
          equipmentTypeId: this.form?.equipmentType?.id,
          page: this.page,
          limit: this.limit
        };

        return pickBy(payload, identity);
      },

      prepareCreateEquipment() {
        this.setCreateMode();
      },

      setCreateMode() {
        this.$emit("changeMode", "create");
        this.mode = this.modes.CREATE;
        this.clearSelectedEquipment();
      },

      setSelectMode() {
        this.$emit("changeMode", "select");
        this.mode = this.modes.SELECT;
      },

      async loadMore($state) {
        this.page = this.page + 1;
        const data = await this.searchEquipments();

        if (data.length) {
          this.equipments = [...this.equipments, ...data];
          $state.loaded();
        } else {
          $state.complete();
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .first-title-mobile {
    min-width: 150px;
  }

  .first-title-desktop {
    min-width: 250px;
  }

  .data-container {
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }
</style>
