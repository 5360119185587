<template>
  <v-card class="pt-5">
    <div :class="{ 'px-10': $isDesktop || $isTablet, 'px-5': $isMobile }">
      <h3 class="mb-2">Tipo:</h3>

      <v-row dense>
        <v-col cols="12">
          <radio-field
            v-model="innerForm.type"
            type="genericText"
            label="Tipo"
            name="requestType"
            :isRequired="true"
            :items="screeningTypes"
            return-object
            row
          />
        </v-col>
      </v-row>
    </div>

    <template v-if="hasSelectedEquipment">
      <form-equipment :fnSubmit="handleSubmit">
        <template v-slot:actions="{ invalid }">
          <form-actions
            :actionDisabled="invalid"
            :fnBackAction="handleBackAction"
          />
        </template>
      </form-equipment>
    </template>

    <template v-if="hasSelectedResidue">
      <form-residue :fnSubmit="handleSubmit">
        <template v-slot:actions="{ invalid }">
          <form-actions
            :actionDisabled="invalid"
            :fnBackAction="handleBackAction"
          />
        </template>
      </form-residue>
    </template>

    <template v-if="hasSelectedPart">
      <form-part :fnSubmit="handleSubmit">
        <template v-slot:actions="{ invalid }">
          <form-actions
            :actionDisabled="invalid"
            :fnBackAction="handleBackAction"
          />
        </template>
      </form-part>
    </template>

    <continue-register-dialog
      :state="continueRegisterDialogState"
      :acceptFn="continueRegistering"
      :refuseFn="refuseRegistering"
    />
  </v-card>
</template>

<script>
  import RadioField from "@/modules/core/views/components/RadioField";
  import ContinueRegisterDialog from "@/modules/stock/batch/views/components/ContinueRegisterDialog";
  import FormEquipment from "@/modules/stock/batch/views/components/register-item-second-step/FormEquipment";
  import FormResidue from "@/modules/stock/batch/views/components/register-item-second-step/FormResidue";
  import FormPart from "@/modules/stock/batch/views/components/register-item-second-step/FormPart";
  import FormActions from "@/modules/stock/batch/views/components/register-item-second-step/FormActions";

  import { screeningTypes } from "@/modules/stock/batch/enums/screeningTypes.enum";
  import { batchDestinies } from "@/modules/stock/batch/enums/batchDestinies.enum";

  import { mapGetters, mapActions } from "vuex";

  export default {
    name: "RegisterScreeningSecondStep",

    components: {
      RadioField,
      ContinueRegisterDialog,
      FormEquipment,
      FormResidue,
      FormPart,
      FormActions
    },

    data: () => ({
      continueRegisterDialogState: {
        dialog: false
      },

      innerForm: {
        type: undefined
      },

      selectedEquipment: undefined,

      firstLoadExecuted: false
    }),

    mounted() {
      this.$nextTick(() => {
        this.innerForm = {
          ...this.form
        };
        this.firstLoadExecuted = true;
      });
    },

    watch: {
      innerForm: {
        deep: true,
        handler: function(newVal) {
          if (!this.firstLoadExecuted) {
            return;
          }
          this.setFormData(newVal);
        }
      }
    },

    computed: {
      ...mapGetters({
        form: "stock/batch/getFormData"
      }),

      screeningTypes() {
        return Object.values(screeningTypes);
      },

      hasSelectedEquipment() {
        return this.innerForm.type === screeningTypes.EQUIPMENT.id;
      },

      hasSelectedResidue() {
        return this.innerForm.type === screeningTypes.RESIDUE.id;
      },

      hasSelectedPart() {
        return this.innerForm.type === screeningTypes.PART.id;
      }
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),

      ...mapActions("stock/batch", [
        "resetCreateState",
        "setCurrentStep",
        "getBrands",
        "setFormData",
        "createBatchItem",
        "keepCreate"
      ]),

      handleBackAction() {
        this.setCurrentStep(1);
      },

      patchFormData(formData) {
        formData.batch = this.form.batch;
        formData.type = this.innerForm.type;
        if (this.hasSelectedResidue) {
          formData.destiny = {
            id: batchDestinies.UNMANUFACTURING.id
          };
        }
      },

      handleSubmit(formData) {
        this.patchFormData(formData);
        this.callCreateBatchItem(formData);
      },

      async callCreateBatchItem(payload) {
        try {
          await this.createBatchItem(payload);
          this.confirmContinueRegistering();
        } catch (error) {
          this.toggleSnackbar({
            text: error.data?.message ?? "Ocorreu um erro inesperado",
            type: "error"
          });
        }
      },

      confirmContinueRegistering() {
        this.continueRegisterDialogState.dialog = true;
      },

      closeContinueRegistering() {
        this.continueRegisterDialogState.dialog = false;
      },

      continueRegistering() {
        this.closeContinueRegistering();
        this.keepCreate();
      },

      refuseRegistering() {
        this.closeContinueRegistering();
        this.$router.push({ name: "screening" });
      }
    }
  };
</script>

<style></style>
