<template>
  <data-not-found :title="text" :imgSrc="require('@/assets/batch.svg')" />
</template>

<script>
  import DataNotFound from "@/modules/core/views/components/DataNotFound.vue";
  export default {
    nome: "BatchNotFound",

    props: {
      title: {
        type: String,
        required: false
      }
    },

    components: { DataNotFound },

    computed: {
      text() {
        return this.title ?? "Lote não encontrado!";
      }
    }
  };
</script>

<style></style>
