<template>
  <div>
    <v-row dense>
      <v-col cols="12">
        <select-most-used-equipment-types
          :value="form.equipmentType"
          @input="handleEquipmentTypeChange"
        />
      </v-col>
    </v-row>

    <equipment-picker-dialog
      :key="`equipment-picker-dialog-${equipmentPickerDialogKey}`"
      :step="equipmentPickerStep"
      :state="equipmentPickerDialogState"
      :equipmentType="form.equipmentType"
      @selectedEquipment="handleSelectedEquipment"
      @cancel="handleCancelSelectEquipment"
    />

    <v-row class="mb-4">
      <v-col cols="12">
        <btn-dashed
          :btnAction="handleOtherEquipmentPicker"
          depressed
          block
          :title="selectEquipmentLabel"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import BtnDashed from "@/modules/core/views/components/BtnDashed";
  import SelectMostUsedEquipmentTypes from "@/modules/stock/batch/views/components/equipment-picker/SelectMostUsedEquipmentTypes";
  import EquipmentPickerDialog from "@/modules/stock/batch/views/components/equipment-picker/EquipmentPickerDialog";

  export default {
    name: "EquipmentPicker",

    props: {
      value: {
        type: null,
        required: true
      }
    },

    components: {
      SelectMostUsedEquipmentTypes,
      BtnDashed,
      EquipmentPickerDialog
    },

    data: () => ({
      form: {
        id: undefined,
        equipmentType: {
          id: undefined,
          description: undefined
        },
        brand: {
          id: undefined,
          description: undefined
        },
        model: undefined
      },

      equipmentPickerStep: 1,
      equipmentPickerDialogKey: 1,
      equipmentPickerDialogState: {
        dialog: false
      }
    }),

    watch: {
      "equipmentPickerDialogState.dialog": function(newVal) {
        if (!newVal) {
          this.equipmentPickerDialogKey++;
        }
      }
    },

    computed: {
      selectEquipmentLabel() {
        return this.form?.equipmentType?.id
          ? `Equipamento: ${this.form?.equipmentType?.description}`
          : "Outros Equipamentos";
      }
    },

    methods: {
      clearForm() {
        this.form.id = undefined;
        this.form.equipmentType.id = undefined;
        this.form.equipmentType.description = undefined;
        this.form.brand.id = undefined;
        this.form.brand.description = undefined;
        this.form.model = undefined;
      },

      prepareEquipmentPicker() {
        this.equipmentPickerDialogState.dialog = true;
      },

      handleOtherEquipmentPicker() {
        this.equipmentPickerStep = 1;
        this.prepareEquipmentPicker();
      },

      handleSelectedEquipment(equipment) {
        this.form = equipment;
        this.$emit("input", this.form);
      },

      handleCancelSelectEquipment() {
        this.clearForm();
        this.$emit("input", this.form);
      },

      handleEquipmentTypeChange(equipmentType) {
        this.form.equipmentType = { ...equipmentType };
        this.equipmentPickerStep = 2;
        this.prepareEquipmentPicker();
      }
    }
  };
</script>

<style></style>
