<template>
  <v-checkbox
    v-model="selectedPart"
    :multiple="false"
    :value="part"
    dense
    color="primary-color-2"
  >
    <template slot="label">
      <div class="d-flex fill">
        <span
          class="black-1--text"
          :class="{
            'first-label-desktop': !$isMobile,
            'first-label-mobile': $isMobile
          }"
          >{{ part.brand.description }}</span
        >
        <span class="black-1--text">{{ part.model }}</span>
      </div>
    </template>
  </v-checkbox>
</template>

<script>
  export default {
    name: "SelectPartCheckbox",

    props: {
      value: {
        type: null
      },
      part: {
        type: Object,
        required: true
      }
    },

    computed: {
      selectedPart: {
        get() {
          return this.value;
        },
        set(newVal) {
          this.$emit("input", newVal);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .first-label-mobile {
    min-width: 120px;
  }

  .first-label-desktop {
    min-width: 220px;
  }
</style>
