<template>
  <validation-observer>
    <v-form @submit.prevent="handleSubmit" slot-scope="{ invalid }">
      <div :class="{ 'px-10': $isDesktop || $isTablet, 'px-5': $isMobile }">
        <h3 class="mb-2">Resíduo:</h3>

        <v-row dense>
          <v-col cols="12">
            <weight-field
              v-model="form.weight"
              name="weight"
              label="Peso"
              :isRequired="true"
            />
          </v-col>
        </v-row>
      </div>
      <slot name="actions" :invalid="invalid"></slot>
    </v-form>
  </validation-observer>
</template>

<script>
  import WeightField from "@/modules/core/views/components/WeightField";

  import { ValidationObserver } from "vee-validate";

  export default {
    name: "FormResidue",

    components: {
      ValidationObserver,
      WeightField
    },

    props: {
      fnSubmit: {
        type: Function,
        required: true
      }
    },

    data: () => ({
      form: {}
    }),

    methods: {
      handleSubmit() {
        this.fnSubmit({
          weight: parseFloat(this.form.weight)
        });
      }
    }
  };
</script>

<style></style>
