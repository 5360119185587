<template>
  <div v-if="state.dialog && mode">
    <v-dialog v-model="state.dialog" :persistent="true" width="588">
      <v-card class="modal">
        <v-card-title class="d-flex justify-space-between black-1--text">
          <span>{{ mode.title }}</span>
          <span class="sub-title">{{ stepDescription }}</span>
        </v-card-title>

        <template v-if="stepper.currentStep === steps.FIRST_STEP">
          <select-part-type v-model="form.partType" />
        </template>

        <template v-if="stepper.currentStep === steps.SECOND_STEP">
          <select-or-create-part
            class="mb-5"
            :form="form"
            :fnPatchForm="patchForm"
            :fromInventory="fromInventory"
            :readonly="readonly"
            @changeMode="handleSecondStepChangeMode"
            @changeValidation="handleSecondStepChangeValidation"
          />
        </template>

        <v-divider></v-divider>

        <v-card-actions class="d-flex flex-wrap justify-space-between">
          <v-btn
            class="mt-1 cancel-button black-3--text"
            color="#ffffff"
            :block="$isMobile"
            @click="mode.fnCancel"
            >{{ mode.labelButtonCancel }}</v-btn
          >
          <v-btn
            type="button"
            color="secondary-color-1"
            :block="$isMobile"
            :disabled="mode.fnValidateButtonAction()"
            class="ma-0 mt-1 next-step-button white--text"
            @click="mode.fnAction"
            >{{ mode.labelButtonAction }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import SelectPartType from "@/modules/stock/batch/views/components/part-picker/SelectPartType";
  import SelectOrCreatePart from "@/modules/stock/batch/views/components/part-picker/SelectOrCreatePart";

  import { mapActions } from "vuex";

  export default {
    name: "PartPickerDialog",

    props: {
      state: {
        type: Object,
        required: true
      },

      step: {
        type: Number,
        required: false,
        default: () => 1
      },

      partType: {
        type: Object,
        required: true
      },

      fromInventory: {
        type: Boolean,
        required: false,
        default: () => false
      },

      readonly: {
        type: Boolean,
        required: false,
        default: () => false
      }
    },

    components: {
      SelectPartType,
      SelectOrCreatePart
    },

    data: () => ({
      stepper: {
        currentStep: undefined,
        maxSteps: 2
      },

      steps: {
        FIRST_STEP: 1,
        SECOND_STEP: 2
      },

      secondStepMode: "select",
      secondStepInvalid: true,

      form: {
        id: undefined,
        stockId: undefined,
        stockAmount: undefined,
        partType: undefined,
        brand: undefined,
        model: undefined
      }
    }),

    watch: {
      "state.dialog": function(newVal) {
        if (newVal) {
          this.onDialogOpen();
        }
      }
    },

    computed: {
      stepDescription() {
        return `Passo ${this.stepper.currentStep} de ${this.stepper.maxSteps}`;
      },

      modes() {
        return {
          [this.steps.FIRST_STEP]: {
            title: "Selecionar Componente",
            labelButtonAction: "Próximo",
            labelButtonCancel: "Cancelar",
            fnValidateButtonAction: this.validateFirstStep,
            fnAction: this.firstStepAction,
            fnCancel: this.firstStepCancel
          },
          [this.steps.SECOND_STEP]: {
            title: this.mountTitleSecondStep(),
            labelButtonAction: "Salvar",
            labelButtonCancel: "Voltar",
            fnValidateButtonAction: this.validateSecondStep,
            fnAction: this.secondStepAction,
            fnCancel: this.secondStepCancel
          }
        };
      },

      mode() {
        return this.modes[this.stepper.currentStep];
      }
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("stock/batch", ["createPart"]),

      onDialogOpen() {
        this.stepper.currentStep = this.step;
        if (this.partType) {
          this.form.partType = this.partType;
        }
      },

      validateFirstStep() {
        return !this.form?.partType?.id;
      },

      validateSecondStep() {
        return (
          !this.form.brand?.id || !this.form.partType?.id || !this.form.model
        );
      },

      firstStepAction() {
        this.stepper.currentStep = 2;
      },

      firstStepCancel() {
        this.state.dialog = false;
        this.$emit("cancel");
      },

      secondStepCancel() {
        this.stepper.currentStep = 1;
      },

      secondStepAction() {
        if (this.secondStepMode === "create") {
          this.callCreatePart(this.form);
        }
        if (this.secondStepMode === "select") {
          this.emitSelectEquipment();
        }
      },

      async callCreatePart(part) {
        try {
          const response = await this.createPart({ ...part });

          this.toggleSnackbar({
            text: response?.data?.message ?? "Registro criado com sucesso!",
            type: "success"
          });

          this.patchForm(response.data);
          this.emitSelectEquipment();
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro, tente novamente",
            type: "error"
          });
        }
      },

      emitSelectEquipment() {
        this.$emit("selectedPart", {
          ...this.form,
          partType: {
            ...this.form.partType
          },
          brand: {
            ...this.form.brand
          }
        });
        this.state.dialog = false;
      },

      mountTitleSecondStep() {
        const prefix =
          this.secondStepMode === "select" ? "Selecionar" : "Cadastrar";

        const body = this.form.partType?.description;

        return `${prefix} ${body}`;
      },

      patchForm(part) {
        if (!part) {
          this.form.id = undefined;
          this.form.brand = undefined;
          this.form.model = undefined;
          this.form.stockId = undefined;
          this.form.stockAmount = undefined;
          return;
        }
        this.form.id = part.id;
        this.form.brand = { ...part.brand };
        this.form.model = part.model;
        this.form.stockId = part.stockId;
        this.form.stockAmount = part.stockAmount;
      },

      handleSecondStepChangeValidation(isValid) {
        this.secondStepInvalid = !isValid;
      },

      handleSecondStepChangeMode(newMode) {
        this.secondStepMode = newMode;
      }
    }
  };
</script>

<style></style>
