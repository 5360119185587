<template>
  <v-container class="content-page-half">
    <title-header :title="title" :subtitle="subtitle" />
    <register-item-first-step v-if="currentStep === 1" />
    <register-item-second-step
      :key="`second-step-${createKey}`"
      v-if="currentStep === 2"
    />
  </v-container>
</template>

<script>
  import TitleHeader from "@/modules/core/views/components/TitleHeader";
  import RegisterItemFirstStep from "@/modules/stock/batch/views/components/RegisterItemFirstStep";
  import RegisterItemSecondStep from "@/modules/stock/batch/views/components/register-item-second-step/RegisterItemSecondStep";

  import { mapGetters, mapActions } from "vuex";
  import store from "@/store";

  export default {
    name: "RegisterScreening",

    beforeRouteLeave: (to, from, next) => {
      store.dispatch("stock/batch/resetCreateState");
      next();
    },

    components: {
      TitleHeader,
      RegisterItemFirstStep,
      RegisterItemSecondStep
    },

    data: () => ({
      maxSteps: 2
    }),

    computed: {
      ...mapGetters({
        currentStep: "stock/batch/getCurrentStep",
        createKey: "stock/batch/getCreateKey",
        batchNumber: "stock/batch/getCreateBatchNumber"
      }),

      subtitle() {
        return `Passo ${this.currentStep} de ${this.maxSteps}`;
      },

      title() {
        if (this.currentStep === 2 && this.batchNumber) {
          return `Registro lote ${this.batchNumber}`;
        }
        return "Novo Lote";
      }
    },

    methods: {
      ...mapActions("stock/batch", ["setCurrentStep"])
    }
  };
</script>

<style lang="scss" scoped></style>
