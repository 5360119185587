<template>
  <div>
    <div class="px-5">
      <v-text-field
        solo
        flat
        full-width
        background-color="white-1"
        color="secondary-color-1"
        placeholder="Buscar (Ex: Lote, Doador ou Parceiro)"
        prepend-inner-icon="mdi-magnify"
        @input="debounceInput"
      ></v-text-field>
    </div>

    <div v-if="batchs.length">
      <div class="d-flex mx-5">
        <div class="batch-header">
          <h3 class="black-3--text text-center">Lote</h3>
        </div>
        <h3 class="black-3--text">Doador/Parceiro</h3>
      </div>
    </div>

    <v-divider></v-divider>

    <div
      v-if="
        batchs.length && hasInnerSelectedBatch && !innerSelectedBatchIsInList
      "
      class="mx-7"
    >
      <select-batch-checkbox
        v-model="selectedBatch"
        :batch="innerSelectedBatch"
      />
    </div>

    <div v-if="batchs.length" class="data-container mx-7">
      <div v-for="(batch, index) in batchs" :key="`batch-${index}`">
        <select-batch-checkbox v-model="selectedBatch" :batch="batch" />
      </div>
      <div>
        <infinite-loading spinner="waveDots" @infinite="loadMore">
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>
      </div>
    </div>

    <div v-if="!batchs.length" class="mt-5">
      <batch-not-found :title="dataNotFound" />
    </div>
  </div>
</template>

<script>
  import BatchNotFound from "@/modules/stock/batch/views/components/BatchNotFound";
  import SelectBatchCheckbox from "@/modules/stock/batch/views/components/SelectBatchCheckbox";

  import InfiniteLoading from "vue-infinite-loading";
  import { debounce, pickBy, identity } from "lodash";
  import { mapActions } from "vuex";

  export default {
    name: "SelectBatch",

    props: {
      value: {
        type: null
      },
      situations: {
        type: Array,
        required: false
      }
    },

    components: {
      BatchNotFound,
      InfiniteLoading,
      SelectBatchCheckbox
    },

    data: () => ({
      page: 1,
      limit: 10,

      searchInput: "",

      batchs: [],
      filterPristine: true,

      innerSelectedBatch: null
    }),

    async mounted() {
      this.batchs = await this.searchBatchs();
    },

    computed: {
      selectedBatch: {
        get() {
          if (!this.innerSelectedBatch && !!this.value) {
            return this.value;
          }
          return this.innerSelectedBatch;
        },
        set(newVal) {
          this.innerSelectedBatch = newVal;
          this.$emit("input", this.innerSelectedBatch);
        }
      },

      hasInnerSelectedBatch() {
        return !!this.innerSelectedBatch;
      },

      innerSelectedBatchIsInList() {
        return this.batchs.some(
          batch => batch.id === this.innerSelectedBatch.id
        );
      },

      dataNotFound() {
        return this.filterPristine
          ? "Busque por um lote"
          : "Lote não encontrado!";
      }
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("stock/batch", ["getBatchsSimplified"]),

      clear() {
        this.page = 1;
        this.batchs = [];
      },

      debounceInput: debounce(async function(value) {
        this.clear();
        this.filterPristine = false;
        this.searchInput = value;
        this.batchs = await this.searchBatchs();
      }, 800),

      mountPayload() {
        const payload = {
          search: this.searchInput,
          situations: this.situations,
          page: this.page,
          limit: this.limit
        };

        return pickBy(payload, identity);
      },

      async searchBatchs() {
        try {
          const payload = this.mountPayload();
          const { data } = await this.getBatchsSimplified(payload);

          return this.formatData(data);
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro, tente novamente",
            type: "error"
          });
        }
      },

      formatData(data) {
        return data.map(batch => ({
          id: batch.id,
          batchNumber: batch.batchNumber,
          donatorOrPartner:
            batch.collectionRequest?.sourceName || "Não informado"
        }));
      },

      async loadMore($state) {
        this.page = this.page + 1;
        const data = await this.searchBatchs();

        if (data.length) {
          this.batchs = [...this.batchs, ...data];
          $state.loaded();
        } else {
          $state.complete();
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .data-container {
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .batch {
    width: 100px;
  }

  .batch-header {
    width: 172px;
  }
</style>
