export const batchItemTypes = {
  EQUIPMENT: {
    description: "Equipamento",
    id: "E"
  },
  RESIDUE: {
    description: "Resíduo",
    id: "R"
  },
  PART: {
    description: "Componente",
    id: "P"
  }
};
