<template>
  <v-checkbox
    v-model="selectedEquipment"
    :multiple="false"
    :value="equipment"
    dense
    color="primary-color-2"
  >
    <template slot="label">
      <span class="flex-grow-0 ml-3 black-1--text">{{
        equipment.description
      }}</span>
    </template>
  </v-checkbox>
</template>

<script>
  export default {
    name: "SelectEquipmentTypeCheckbox",

    props: {
      value: {
        type: null
      },
      equipment: {
        type: Object,
        required: true
      }
    },

    computed: {
      selectedEquipment: {
        get() {
          return this.value;
        },
        set(newVal) {
          this.$emit("input", newVal);
        }
      }
    }
  };
</script>

<style></style>
