<template>
  <radio-field
    v-model="selectedEquipment"
    :itemWidth="120"
    name="mostUsedEquipments"
    :isRequired="false"
    :items="mostUsedEquipments"
    row
  />
</template>

<script>
  import RadioField from "@/modules/core/views/components/RadioField";

  import { mapActions } from "vuex";

  export default {
    name: "SelectMostUsedEquipmentTypes",

    props: {
      value: {
        type: null
      }
    },

    components: {
      RadioField
    },

    data: () => ({
      mostUsedEquipments: []
    }),

    async mounted() {
      await this.fetchMostUsedEquipments();
    },

    computed: {
      selectedEquipment: {
        get() {
          return this.value?.id;
        },
        set(newVal) {
          this.$emit("input", this.getEquipmentById(newVal));
        }
      }
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("stock/batch", ["getEquipmentTypesMostUsed"]),

      getEquipmentById(id) {
        return this.mostUsedEquipments.find(equipment => equipment.id === id);
      },

      hasEquimentInMostUsedList(id) {
        return !!this.getEquipmentById(id);
      },

      async fetchMostUsedEquipments() {
        try {
          const { data } = await this.getEquipmentTypesMostUsed();

          this.mostUsedEquipments = this.formatEquipmentTypes(data);
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro, tente novamente",
            type: "error"
          });
        }
      },

      formatEquipmentTypes(equipmentTypes) {
        return equipmentTypes.map(equipmentType => {
          return {
            id: equipmentType.id,
            description: equipmentType.description
          };
        });
      }
    }
  };
</script>

<style></style>
