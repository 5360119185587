<template>
  <v-checkbox
    v-model="selectedPart"
    :multiple="false"
    :value="part"
    dense
    color="primary-color-2"
  >
    <template slot="label">
      <span class="ml-3 black-1--text">{{ part.description }}</span>
    </template>
  </v-checkbox>
</template>

<script>
  export default {
    name: "SelectPartTypeCheckbox",

    props: {
      value: {
        type: null
      },
      part: {
        type: Object,
        required: true
      }
    },

    computed: {
      selectedPart: {
        get() {
          return this.value;
        },
        set(newVal) {
          this.$emit("input", newVal);
        }
      }
    }
  };
</script>

<style></style>
