<template>
  <validation-provider
    :name="name"
    :rules="{ required: isRequired }"
    v-slot="{ errors }"
  >
    <v-text-field
      v-model="computedValue"
      :error-messages="errors[0]"
      type="number"
      min="0"
      step="1"
      maxlength="6"
      maxlen
      v-bind="{ ...$attrs, ...attrs }"
      v-on="$listeners"
    ></v-text-field>
  </validation-provider>
</template>

<script>
  import { ValidationProvider } from "vee-validate";

  import MasksMixin from "@/modules/core/mixins/masksMixin";
  import FieldMixin from "@/modules/core/mixins/fieldMixin";

  export default {
    name: "WeightField",

    props: {
      value: {
        type: null
      }
    },

    mixins: [MasksMixin, FieldMixin],

    components: {
      ValidationProvider
    },

    data: () => ({
      maxLength: 7
    }),

    computed: {
      computedValue: {
        get() {
          return this.value;
        },
        set(newVal) {
          this.$emit("input", newVal);
        }
      }
    }
  };
</script>

<style></style>
