<template>
  <v-card class="pt-5">
    <validation-observer>
      <v-form @submit.prevent="submitForm" slot-scope="{ invalid }">
        <div :class="{ 'px-10': $isDesktop || $isTablet, 'px-5': $isMobile }">
          <h3 class="mb-2">Selecionar lote:</h3>
          <v-row>
            <v-col cols="12">
              <btn-dashed
                :btnAction="openSelectBatch"
                depressed
                block
                :title="selectBatchLabel"
              />
            </v-col>
          </v-row>

          <h3 class="mb-2 mt-6">Informações:</h3>

          <v-row dense>
            <v-col cols="12">
              <date-field
                :value="currentDate"
                :disabled="true"
                label="Data de Registro"
                :isRequired="true"
              />
            </v-col>
          </v-row>
        </div>

        <select-batch-dialog
          :state="selectBatchState"
          :situations="selectBatchSituations"
          @selectedBatch="handleSelectedBatch"
        />

        <v-divider></v-divider>

        <v-card-actions class="d-flex flex-wrap justify-space-between">
          <v-btn
            @click="$router.push({ name: 'screening' })"
            color="#FFF"
            class="mt-1 cancel-button black-3--text"
            :block="$isMobile"
            >Cancelar</v-btn
          >
          <v-btn
            type="submit"
            :disabled="invalid || !hasSelectedBatch"
            color="secondary-color-1"
            :block="$isMobile"
            class="ma-0 mt-1 next-step-button white--text"
            >Inserir Detalhes</v-btn
          >
        </v-card-actions>
      </v-form>
    </validation-observer>
  </v-card>
</template>

<script>
  import { ValidationObserver } from "vee-validate";
  import DateField from "@/modules/core/views/components/DateField";
  import BtnDashed from "@/modules/core/views/components/BtnDashed";
  import SelectBatchDialog from "@/modules/stock/batch/views/components/SelectBatchDialog";
  import { requestSituation } from "@/modules/agenda/enums/request-situation.enum";

  import { mapGetters, mapActions } from "vuex";

  export default {
    name: "RegisterItemFirstStep",

    components: {
      ValidationObserver,
      DateField,
      BtnDashed,
      SelectBatchDialog
    },

    data: () => ({
      selectBatchState: {
        dialog: false
      },

      innerForm: {
        batch: undefined
      },

      firstLoadExecuted: false,

      selectBatchSituations: []
    }),

    mounted() {
      this.selectBatchSituations = [
        requestSituation.DONE.id,
        requestSituation.IN_PROGRESS.id
      ];

      this.$nextTick(() => {
        this.innerForm = {
          ...this.form
        };
        this.firstLoadExecuted = true;
      });
    },

    watch: {
      innerForm: {
        deep: true,
        handler: function(newVal) {
          if (!this.firstLoadExecuted) {
            return;
          }
          this.setFormData(newVal);
        }
      }
    },

    computed: {
      ...mapGetters({
        form: "stock/batch/getFormData"
      }),
      selectBatchLabel() {
        return this.innerForm?.batch?.batchNumber
          ? `Lote: ${this.innerForm.batch.batchNumber}`
          : "Selecionar lote";
      },

      hasSelectedBatch() {
        return !!this.innerForm?.batch?.id;
      },

      currentDate() {
        return new Date().toISOString().substr(0, 10);
      }
    },

    methods: {
      ...mapActions("stock/batch", ["setCurrentStep", "setFormData"]),

      submitForm() {
        this.setCurrentStep(2);
      },

      openSelectBatch() {
        this.selectBatchState.dialog = true;
      },

      handleSelectedBatch(batch) {
        this.innerForm = {
          ...this.innerForm,
          batch
        };
      },

      handleChangeRegisterDate(newDate) {
        this.innerForm = {
          ...this.innerForm,
          registerDate: newDate
        };
      }
    }
  };
</script>

<style lang="scss" scoped></style>
