import { batchItemTypes } from "@/modules/stock/batch/enums/batchItemTypes.enum";

export const screeningTypes = {
  EQUIPMENT: {
    ...batchItemTypes.EQUIPMENT
  },
  RESIDUE: {
    ...batchItemTypes.RESIDUE
  },
  PART: {
    ...batchItemTypes.PART
  }
};

export const screeningStatus = {
  IN_SCREENING: 1,
  CONCLUDED: 2
};
