<template>
  <data-not-found :title="text" :imgSrc="require('@/assets/part.svg')" />
</template>

<script>
  import DataNotFound from "@/modules/core/views/components/DataNotFound.vue";

  export default {
    name: "PartNotFound",

    props: {
      title: {
        type: Array,
        required: false
      }
    },

    components: {
      DataNotFound
    },

    computed: {
      text() {
        return this.title ?? "Componente não encontrado!";
      }
    }
  };
</script>

<style></style>
