<template>
  <radio-field
    v-model="selectedPart"
    :itemWidth="120"
    name="mostUsedParts"
    :isRequired="false"
    :items="mostUsedParts"
    row
  />
</template>

<script>
  import RadioField from "@/modules/core/views/components/RadioField";

  import { mapActions } from "vuex";

  export default {
    name: "SelectMostUsedPartTypes",

    props: {
      value: {
        type: null
      }
    },

    components: {
      RadioField
    },

    data: () => ({
      mostUsedParts: []
    }),

    async mounted() {
      await this.fetchMostUsedParts();
    },

    computed: {
      selectedPart: {
        get() {
          return this.value?.id;
        },
        set(newVal) {
          this.$emit("input", this.getPartById(newVal));
        }
      }
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("stock/batch", ["getPartTypesMostUsed"]),

      getPartById(id) {
        return this.mostUsedParts.find(part => part.id === id);
      },

      hasPartInMostUsedList(id) {
        return !!this.getPartById(id);
      },

      async fetchMostUsedParts() {
        try {
          const { data } = await this.getPartTypesMostUsed();

          this.mostUsedParts = this.formatPartTypes(data);
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro, tente novamente",
            type: "error"
          });
        }
      },

      formatPartTypes(partTypes) {
        return partTypes.map(partType => {
          return {
            id: partType.id,
            description: partType.description
          };
        });
      }
    }
  };
</script>

<style></style>
