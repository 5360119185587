<template>
  <div>
    <v-row dense>
      <v-col cols="12">
        <select-most-used-part-types
          :value="form.partType"
          @input="handlePartTypeChange"
        />
      </v-col>
    </v-row>

    <part-picker-dialog
      :state="partPickerDialogState"
      :step="partPickerStep"
      :key="`part-picker-dialog-${partPickerDialogKey}`"
      :partType="form.partType"
      :fromInventory="fromInventory"
      :readonly="readonly"
      @selectedPart="handleSelectPart"
      @cancel="handleCancelSelectPart"
    />

    <v-row class="mb-4">
      <v-col cols="12">
        <btn-dashed
          :btnAction="handleOtherPartPicker"
          depressed
          block
          :title="selectPartLabel"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import BtnDashed from "@/modules/core/views/components/BtnDashed";
  import SelectMostUsedPartTypes from "@/modules/stock/batch/views/components/part-picker/SelectMostUsedPartTypes";
  import PartPickerDialog from "@/modules/stock/batch/views/components/part-picker/PartPickerDialog";

  export default {
    name: "PartPicker",

    props: {
      fromInventory: {
        type: Boolean,
        required: false,
        default: () => false
      },

      readonly: {
        type: Boolean,
        required: false,
        default: () => false
      }
    },

    components: {
      SelectMostUsedPartTypes,
      BtnDashed,
      PartPickerDialog
    },

    data: () => ({
      partPickerStep: 1,
      partPickerDialogKey: 1,
      partPickerDialogState: {
        dialog: false
      },

      form: {
        id: undefined,
        partType: {
          id: undefined,
          description: undefined
        },
        brand: {
          id: undefined,
          description: undefined
        },
        model: undefined
      }
    }),

    watch: {
      "partPickerDialogState.dialog": function(newVal) {
        if (!newVal) {
          this.partPickerDialogKey++;
        }
      }
    },

    computed: {
      selectPartLabel() {
        return this.form?.partType?.id
          ? `Componente: ${this.form.partType.description}`
          : "Outros Componentes";
      }
    },

    methods: {
      clearForm() {
        this.form.id = undefined;
        this.form.partType.id = undefined;
        this.form.partType.description = undefined;
        this.form.brand.id = undefined;
        this.form.brand.description = undefined;
        this.form.model = undefined;
      },

      preparePartPicker() {
        this.partPickerDialogState.dialog = true;
      },

      handleOtherPartPicker() {
        this.partPickerStep = 1;
        this.preparePartPicker();
      },

      handleSelectPart(part) {
        this.form = part;
        this.$emit("input", this.form);
      },

      handleCancelSelectPart() {
        this.clearForm();
        this.$emit("input", this.form);
      },

      handlePartTypeChange(partType) {
        this.form.partType = { ...partType };
        this.partPickerStep = 2;
        this.preparePartPicker();
      }
    }
  };
</script>

<style></style>
