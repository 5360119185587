<template>
  <div v-if="state.dialog">
    <v-dialog v-model="state.dialog" :persistent="true" width="488">
      <v-card class="modal">
        <v-card-title class="black-1--text">
          Selecionar Lote
        </v-card-title>

        <select-batch v-model="selectedBatch" :situations="situations" />

        <v-divider></v-divider>

        <v-card-actions class="d-flex flex-wrap justify-space-between">
          <v-btn
            class="mt-1 cancel-button black-3--text"
            color="#ffffff"
            :block="$isMobile"
            @click="state.dialog = false"
            >Cancelar</v-btn
          >
          <v-btn
            type="button"
            color="secondary-color-1"
            :block="$isMobile"
            class="ma-0 mt-1 next-step-button white--text"
            :disabled="!selectedBatch"
            @click="submitForm"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import SelectBatch from "@/modules/stock/batch/views/components/SelectBatch";
  import { requestSituation } from "@/modules/agenda/enums/request-situation.enum";

  export default {
    name: "SelectBatchDialog",

    props: {
      state: {
        type: Object,
        required: true
      },
      situations: {
        type: Array,
        required: true,
        validation: situationIds => {
          const validIds = Object.values(requestSituation).map(
            situation => situation.id
          );

          const valid = situationIds.every(situationId =>
            validIds.includes(situationId)
          );

          return valid;
        }
      }
    },

    data: () => ({
      selectedBatch: undefined
    }),

    components: {
      SelectBatch
    },

    methods: {
      submitForm() {
        this.state.dialog = false;
        this.$emit("selectedBatch", this.selectedBatch);
      }
    }
  };
</script>

<style></style>
