<template>
  <div>
    <div v-if="mode.showFilterInput" class="px-5">
      <v-text-field
        solo
        flat
        full-width
        background-color="white-1"
        color="secondary-color-1"
        placeholder="Buscar (Ex: Marca, modelo)"
        prepend-inner-icon="mdi-magnify"
        @input="debounceInput"
      ></v-text-field>
    </div>

    <div v-if="mode.showList && parts.length">
      <div class="mx-10 d-flex">
        <h3
          class="ml-5 black-3--text"
          :class="{
            'first-title-desktop': !$isMobile,
            'first-title-mobile': $isMobile
          }"
        >
          Marca
        </h3>
        <h3 class="black-3--text">Modelo</h3>
      </div>
    </div>

    <v-divider></v-divider>

    <div v-if="mode.showList && parts.length" class="data-container mx-7">
      <div v-if="hasSelectedPart && !selectedPartIsInList">
        <select-part-checkbox :part="selectedPart" v-model="selectedPart" />
      </div>

      <div v-for="(part, index) in parts" :key="`parts-${index}`">
        <select-part-checkbox :part="part" v-model="selectedPart" />
      </div>

      <div>
        <infinite-loading spinner="waveDots" @infinite="loadMore">
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>
      </div>
    </div>

    <div class="mt-5" v-if="mode.showNotFound && !parts.length && !isLoading">
      <part-not-found :title="dataNotFound" />
    </div>

    <div v-if="mode.showCreateForm" class="mx-10 mt-5">
      <v-row>
        <v-row dense>
          <v-col cols="12" md="6">
            <brand-field name="brand" :isRequired="true" v-model="form.brand" />
          </v-col>
          <v-col cols="12" md="6">
            <field
              v-model="form.model"
              type="genericText"
              label="Modelo"
              name="model"
              :isRequired="true"
            />
          </v-col>
        </v-row>
      </v-row>
    </div>

    <div v-if="!readonly && mode.showBtnCreate" class="mx-5">
      <btn-dashed
        title="Cadastrar Componente"
        :btnAction="prepareCreatePart"
        block
      />
    </div>
  </div>
</template>

<script>
  import PartNotFound from "@/modules/stock/batch/views/components/PartNotFound";
  import SelectPartCheckbox from "@/modules/stock/batch/views/components/part-picker/SelectPartCheckbox";
  import Field from "@/modules/core/views/components/Field";
  import BrandField from "@/modules/stock/batch/views/components/BrandField";
  import BtnDashed from "@/modules/core/views/components/BtnDashed";

  import InfiniteLoading from "vue-infinite-loading";

  import { debounce, pickBy, identity } from "lodash";

  import { mapGetters, mapActions } from "vuex";

  export default {
    name: "SelectOrCreatePart",

    props: {
      form: {
        type: Object,
        required: true
      },

      fnPatchForm: {
        type: Function,
        required: true
      },

      fromInventory: {
        type: Boolean,
        required: false,
        default: () => false
      },

      readonly: {
        type: Boolean,
        required: false,
        default: () => false
      }
    },

    components: {
      InfiniteLoading,
      SelectPartCheckbox,
      PartNotFound,
      Field,
      BrandField,
      BtnDashed
    },

    data: () => ({
      selectedPart: undefined,

      parts: [],

      filterPristine: true,
      searchInput: "",
      page: 1,
      limit: 15,

      mode: undefined,
      modes: {
        SELECT: {
          showFilterInput: true,
          showNotFound: true,
          showCreateForm: false,
          showBtnCreate: true,
          showList: true
        },
        CREATE: {
          showFilterInput: false,
          showNotFound: false,
          showCreateForm: true,
          showBtnCreate: false,
          showList: false
        }
      }
    }),

    created() {
      this.setSelectMode();
    },

    async mounted() {
      this.parts = await this.searchParts();
    },

    watch: {
      selectedPart: {
        deep: true,
        handler: function(newVal) {
          if (newVal) {
            return this.fnPatchForm(newVal);
          }
          return this.fnPatchForm(null);
        }
      }
    },

    computed: {
      ...mapGetters(["isLoading"]),

      hasSelectedPart() {
        return !!this.selectedPart;
      },

      selectedPartIsInList() {
        return this.parts.some(part => part.id === this.selectedPart?.id);
      },

      dataNotFound() {
        return ["Componente não encontrado"];
      }
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("stock/batch", ["getParts"]),
      ...mapActions("stock/partInventory", ["getPartsFromInventory"]),

      clearSearch() {
        this.page = 1;
        this.parts = [];
      },

      debounceInput: debounce(async function(value) {
        this.clearSearch();

        this.filterPristine = false;
        this.searchInput = value;

        this.parts = await this.searchParts();
      }, 800),

      clearSelectedPart() {
        this.selectedPart = undefined;
      },

      setCreateMode() {
        this.$emit("changeMode", "create");
        this.mode = this.modes.CREATE;
        this.clearSelectedPart();
      },

      setSelectMode() {
        this.$emit("changeMode", "select");
        this.mode = this.modes.SELECT;
      },

      async searchParts() {
        try {
          const payload = this.mountPayload();
          const { data } = await this.callGetParts(payload);

          return data;
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro, tente novamente",
            type: "error"
          });
        }
      },

      async callGetParts(payload) {
        if (this.fromInventory) {
          const { data } = await this.getPartsFromInventory(payload);
          return { data: this.formatDataFromInventory(data) };
        }
        return this.getParts(payload);
      },

      formatDataFromInventory(data) {
        return data.map(item => {
          return {
            stockId: item.id,
            stockAmount: item.amount,
            ...item.part
          };
        });
      },

      async loadMore($state) {
        this.page = this.page + 1;
        const data = await this.searchParts();

        if (data.length) {
          this.parts = [...this.parts, ...data];
          $state.loaded();
        } else {
          $state.complete();
        }
      },

      mountPayload() {
        const payload = {
          search: this.searchInput,
          partTypeId: this.form?.partType?.id,
          page: this.page,
          limit: this.limit
        };

        return pickBy(payload, identity);
      },

      prepareCreatePart() {
        this.setCreateMode();
      }
    }
  };
</script>

<style lang="scss" scoped>
  .first-title-mobile {
    min-width: 150px;
  }

  .first-title-desktop {
    min-width: 250px;
  }

  .data-container {
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }
</style>
