import { render, staticRenderFns } from "./RegisterScreening.vue?vue&type=template&id=f95c6268&scoped=true&"
import script from "./RegisterScreening.vue?vue&type=script&lang=js&"
export * from "./RegisterScreening.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f95c6268",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer})
