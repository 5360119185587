<template>
  <v-checkbox
    v-model="selectedBatch"
    :multiple="false"
    :value="batch"
    dense
    color="primary-color-2"
  >
    <template slot="label">
      <span class="batch flex-grow-0 ml-3 black-1--text">{{
        batch.batchNumber
      }}</span>
      <span class="flex-grow-1 ml-5">
        {{ batch.donatorOrPartner }}
      </span>
    </template>
  </v-checkbox>
</template>

<script>
  export default {
    name: "SelectBatchCheckbox",

    props: {
      value: {
        type: null
      },
      batch: {
        type: Object,
        required: true
      }
    },

    computed: {
      selectedBatch: {
        get() {
          return this.value;
        },
        set(newVal) {
          this.$emit("input", newVal);
        }
      }
    }
  };
</script>

<style></style>
