<template>
  <v-card-actions class="d-flex flex-wrap justify-space-between">
    <v-btn
      @click="fnBackAction"
      color="#FFF"
      class="mt-1 cancel-button black-3--text"
      :block="$isMobile"
      >Voltar</v-btn
    >
    <v-btn
      type="submit"
      color="secondary-color-1"
      :disabled="actionDisabled"
      :block="$isMobile"
      class="ma-0 mt-1 next-step-button white--text"
      >Cadastrar</v-btn
    >
  </v-card-actions>
</template>

<script>
  export default {
    name: "FormActions",

    props: {
      actionDisabled: {
        type: Boolean,
        required: true
      },
      fnBackAction: {
        type: Function,
        required: true
      }
    }
  };
</script>

<style></style>
