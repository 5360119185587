<template>
  <validation-provider
    :name="name"
    :rules="{ required: isRequired }"
    v-slot="{ errors }"
  >
    <v-autocomplete
      v-model="selectedBrand"
      :error-messages="errors[0]"
      :items="items"
      item-text="description"
      :loading="isLoading"
      :search-input.sync="searchInput"
      hide-no-data
      hide-selected
      label="Marca"
      placeholder="Ex: Cisco, HP"
      v-bind="{ ...$attrs, ...attrs }"
      v-on="$listeners"
      no-filter
      return-object
    ></v-autocomplete>
  </validation-provider>
</template>

<script>
  import FieldMixin from "@/modules/core/mixins/fieldMixin";

  import { debounce } from "lodash";
  import { ValidationProvider } from "vee-validate";
  import { mapActions } from "vuex";

  export default {
    name: "BrandField",

    props: {
      value: {
        type: null
      },
      isRequired: {
        type: Boolean,
        required: true
      }
    },

    mixins: [FieldMixin],

    data: () => ({
      isLoading: false,

      items: [],

      innerValue: undefined,

      searchInput: ""
    }),

    mounted() {
      if (this.value) {
        this.items = [this.value];
      }
    },

    computed: {
      selectedBrand: {
        get() {
          if (!this.innerValue && this.value) {
            return this.value;
          }
          return this.innerValue;
        },
        set(newVal) {
          this.innerValue = newVal;
          this.$emit("input", this.innerValue);
        }
      }
    },

    watch: {
      searchInput(newVal) {
        if (!newVal) {
          this.clear();
          return;
        }
        this.search();
      }
    },

    components: {
      ValidationProvider
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("stock/batch", ["getBrands"]),

      formatData(items) {
        return items.map(item => ({
          text: item.description,
          value: item
        }));
      },

      clear() {
        this.$emit("input", null);
      },

      search: debounce(function() {
        if (this.searchInput) {
          this.searchBrandsByFilter(this.searchInput);
        }
      }, 400),

      async searchBrandsByFilter(searchInput) {
        this.isLoading = true;

        try {
          const { data } = await this.getBrands({
            search: searchInput
          });
          this.items = data;
        } catch (error) {
          this.toggleSnackbar({
            text: error.data?.message ?? "Ocorreu um erro inesperado",
            type: "error"
          });
        } finally {
          this.isLoading = false;
        }
      }
    }
  };
</script>

<style></style>
