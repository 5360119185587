<template>
  <div>
    <div class="px-5">
      <v-text-field
        solo
        flat
        full-width
        background-color="white-1"
        color="secondary-color-1"
        placeholder="Buscar (Ex: Equipamento)"
        prepend-inner-icon="mdi-magnify"
        @input="debounceInput"
      ></v-text-field>
    </div>

    <div v-if="equipments.length">
      <div class="mx-5">
        <h3 class="black-3--text">Equipamento</h3>
      </div>
    </div>

    <v-divider></v-divider>

    <div v-if="equipments.length" class="data-container mx-7">
      <div v-if="hasInnerSelectedEquipment && !innerSelectedEquipmentIsInList">
        <select-equipment-type-checkbox
          :equipment="innerSelectedEquipment"
          v-model="selectedEquipment"
        />
      </div>

      <div v-for="(equipment, index) in equipments" :key="`equipment-${index}`">
        <select-equipment-type-checkbox
          :equipment="equipment"
          v-model="selectedEquipment"
        />
      </div>
      <div>
        <infinite-loading spinner="waveDots" @infinite="loadMore">
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>
      </div>
    </div>

    <div v-if="!equipments.length && !isLoading" class="mt-5">
      <equipment-not-found :title="dataNotFound" />
    </div>
  </div>
</template>

<script>
  import SelectEquipmentTypeCheckbox from "@/modules/stock/batch/views/components/equipment-picker/SelectEquipmentTypeCheckbox";
  import EquipmentNotFound from "@/modules/stock/batch/views/components/EquipmentNotFound";

  import { debounce, pickBy, identity } from "lodash";
  import { mapGetters, mapActions } from "vuex";
  import InfiniteLoading from "vue-infinite-loading";

  export default {
    name: "SelectEquipmentType",

    props: {
      value: {
        type: null
      }
    },

    components: {
      SelectEquipmentTypeCheckbox,
      InfiniteLoading,
      EquipmentNotFound
    },

    data: () => ({
      equipments: [],

      searchInput: "",
      filterPristine: true,

      page: 1,
      limit: 15,

      innerSelectedEquipment: undefined
    }),

    async mounted() {
      this.equipments = await this.searchEquipmentTypes();
    },

    computed: {
      ...mapGetters(["isLoading"]),
      selectedEquipment: {
        get() {
          if (!this.innerSelectedEquipment && this.value) {
            return this.value;
          }
          return this.innerSelectedEquipment;
        },
        set(newVal) {
          this.innerSelectedEquipment = newVal;
          this.$emit("input", this.innerSelectedEquipment);
        }
      },

      hasInnerSelectedEquipment() {
        return !!this.innerSelectedEquipment;
      },

      innerSelectedEquipmentIsInList() {
        return this.equipments.some(
          equipment => equipment.id === this.innerSelectedEquipment?.id
        );
      },

      dataNotFound() {
        return this.filterPristine
          ? ["Busque por um equipamento"]
          : ["Equipamento não encontrado!"];
      }
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("stock/batch", ["getEquipmentTypes"]),

      clear() {
        this.page = 1;
        this.equipments = [];
      },

      debounceInput: debounce(async function(value) {
        this.clear();

        this.filterPristine = false;
        this.searchInput = value;

        this.equipments = await this.searchEquipmentTypes();
      }, 800),

      async searchEquipmentTypes() {
        try {
          const payload = this.mountPayload();
          const { data } = await this.getEquipmentTypes(payload);

          return data;
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro, tente novamente",
            type: "error"
          });
        }
      },

      async loadMore($state) {
        this.page = this.page + 1;
        const data = await this.searchEquipmentTypes();

        if (data.length) {
          this.equipments = [...this.equipments, ...data];
          $state.loaded();
        } else {
          $state.complete();
        }
      },

      mountPayload() {
        const payload = {
          search: this.searchInput,
          page: this.page,
          limit: this.limit
        };

        return pickBy(payload, identity);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .data-container {
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }
</style>
