export const batchDestinies = {
  UNMANUFACTURING: {
    description: "Desmanufatura",
    id: 1
  },
  RECONDITIONING: {
    description: "Recondicionamento",
    id: 2
  }
};
