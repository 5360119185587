<template>
  <validation-observer>
    <v-form @submit.prevent="handleSubmit" slot-scope="{ invalid }">
      <div :class="{ 'px-10': $isDesktop || $isTablet, 'px-5': $isMobile }">
        <h3 class="mb-3">Componente:</h3>

        <part-picker v-model="form.part" />

        <template>
          <v-row dense>
            <v-col cols="12" md="6">
              <field
                v-model="form.part.brand.description"
                type="genericText"
                label="Marca"
                name="brand"
                :disabled="true"
                :isRequired="true"
              />
            </v-col>
            <v-col cols="12" md="6">
              <field
                v-model="form.part.model"
                type="genericText"
                label="Modelo"
                name="model"
                :disabled="true"
                :isRequired="true"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <field
                v-model="form.destiny.id"
                type="select"
                :items="batchDestinies"
                item-text="description"
                item-value="id"
                label="Área Destinada"
                name="destiny"
                :isRequired="true"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="6">
              <field
                v-model="form.amount"
                type="genericText"
                label="Quantidade"
                name="amount"
                :isRequired="true"
              />
            </v-col>
            <v-col cols="12" md="6">
              <weight-field
                v-model="form.weight"
                name="weight"
                label="Peso Unitário"
                :isRequired="true"
              />
            </v-col>
          </v-row>
        </template>
      </div>
      <slot name="actions" :invalid="invalid"></slot>
    </v-form>
  </validation-observer>
</template>

<script>
  import Field from "@/modules/core/views/components/Field";
  import WeightField from "@/modules/core/views/components/WeightField";
  import PartPicker from "@/modules/stock/batch/views/components/part-picker/PartPicker";

  import { batchDestinies } from "@/modules/stock/batch/enums/batchDestinies.enum";

  import { ValidationObserver } from "vee-validate";

  export default {
    name: "FormPart",

    components: {
      ValidationObserver,
      PartPicker,
      Field,
      WeightField
    },

    props: {
      fnSubmit: {
        type: Function,
        required: true
      }
    },

    data: () => ({
      form: {
        part: {
          id: undefined,
          partType: undefined,
          brand: {
            id: undefined,
            description: undefined
          },
          model: undefined
        },
        destiny: {
          id: undefined
        },
        amount: undefined,
        weight: undefined
      }
    }),

    computed: {
      batchDestinies() {
        return Object.values(batchDestinies);
      }
    },

    methods: {
      handleSubmit() {
        this.fnSubmit({
          ...this.form,
          amount: parseInt(this.form.amount, 10),
          weight: parseFloat(this.form.weight)
        });
      }
    }
  };
</script>

<style></style>
